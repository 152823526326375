<template>
    <div class="zk-dev">
        <div class="doc" v-html="doc" />
    </div>
</template>

<script>
const requestUrl = '//cms-docs.kaola.com/publishModule/getPublishContentByKey.html?key=';
export default {
    data() {
        return {
            doc: ''
        };
    },

    created() {
        this.getDevDoc();
    },

    methods: {
        async getDevDoc() {
            this.doc = '';
            const result = await this.$request({
                url: `${requestUrl}zhuanke_devloper_center`,
                toast: false,
                origin: true
            });
            if(result.code === 200 && result.body) {
                this.doc = result.body.content || '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .zk-dev {
        padding: 16px;
        min-height: 100%;
        background: #fff;
        word-break: break-all;
        .doc {
            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
            }
        }
    }
</style>
